@import '~antd/dist/antd.dark.less';

h1 {
  font-size: 1.42rem;
}

#app {
  min-height: 100vh;
}
#app .trigger {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
}

#app .trigger:hover {
  color: #5C4BB0;
}
#app .ant-layout-sider-trigger {
  background: #081B33;
}

#app .logo {
  margin: 0px;
  background: #081B33;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.3s;
}

.header-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.isotype-wrapper {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.isotype-wrapper img {
  width: 24px;
  height: 28px;
}

// Loading page
.litcheck-loading-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.litcheck-loading-page div {
  display: inline-block;
  position: relative;
  width: 130px;
  height: 130px;
}
.litcheck-loading-page div > div {
  position: absolute;
  background: #826AF9;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.litcheck-loading-page div > div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 60px;
    left: 60px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 120px;
    height: 120px;
    opacity: 0;
  }
}

// Table head
.ant-table-wrapper .ant-table-thead tr th { 
  background: #2F4562;
}
.ant-table-wrapper.custom-table .ant-table-thead tr th { 
  background: #2F4562;
}
// Table body
.ant-table-wrapper .ant-table-tbody tr { 
  background: #152642;
}
.ant-table-wrapper.custom-table .ant-table-tbody tr:nth-child(even) { 
  background: #2F4562;
}
.ant-table-wrapper.custom-table .ant-table-tbody tr:nth-child(odd) { 
  background: #152642;
}
// Hover table
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #192d4e;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #192d4e;
}
.ant-table-wrapper.custom-table .ant-table-tbody > tr.ant-table-row:hover:nth-child(even) > td {
  background: #334c6b;
}
.ant-table-wrapper.custom-table .ant-table-tbody > tr.ant-table-row:hover:nth-child(odd) > td {
  background: #192d4e;
}

// Message
.ant-message {
  display: flex;
  justify-content: flex-end;
  top: 64px;
  padding-right: 12px;
}

// Delete Modal.confirm
.delete-modal-confirm.ant-modal-confirm .ant-modal-confirm-title {
  text-align: center;
}
.delete-modal-confirm.ant-modal-confirm .ant-modal-body {
  background-color: #2F4562;
}
.delete-modal-confirm.ant-modal-confirm .ant-modal-confirm-content {
  color: #00ABFF;
}
.delete-modal-confirm.ant-modal-confirm .ant-modal-confirm-btns {
  width: 100%;
  text-align: center;
}
.delete-modal-confirm.ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  min-width: 120px;
}
.delete-modal-confirm.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-left: 24px;
}
@primary-color: #0F4D90;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #FFCF00;@error-color: #F23868;