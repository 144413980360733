.billing-section-title {
  border-bottom: 1px solid #303030;
  padding: 12px 0px;
  margin-bottom: 24px;
  font-size: 20px;
}
.billing-plan-title {
  color: #72b6ff;
  font-size: 18px;
}
.billing-plan-price {
  color: #dec350;
  font-size: 16px;
}

.billing-collapse .ant-collapse-header {
  text-align: center;
  font-size: 16px;
}
.payment-receipt {
  text-align: center;
}

.payment-receipt .response-icon.anticon {
  font-size: 100px;
  margin-bottom: 24px;
}

.payment-receipt .response-title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.payment-receipt .response-subtitle {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 22px;
  margin: 0px 50px 15px;
}

.confetti {
  width: 15px;
  height: 15px;
  background-color: #f2d74e;
  position: absolute;
  left: 50%;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
}
.confetti:nth-child(1) {
  background-color: #f2d74e; left: 10%; animation-delay: 0;
}
.confetti:nth-child(2) {
  background-color: #95c3de; left: 20%; animation-delay: -5s;
}
.confetti:nth-child(3) {
  background-color: #ff9a91; left: 30%; animation-delay: -3s;
}
.confetti:nth-child(4) {
  background-color: #f2d74e; left: 40%; animation-delay: -2.5s;
}
.confetti:nth-child(5) {
  background-color: #95c3de; left: 50%; animation-delay: -4s;
}
.confetti:nth-child(6) {
  background-color: #ff9a91; left: 60%; animation-delay: -6s;
}
.confetti:nth-child(7) {
  background-color: #f2d74e; left: 70%; animation-delay: -1.5s;
}
.confetti:nth-child(8) {
  background-color: #95c3de; left: 80%; animation-delay: -2s;
}
.confetti:nth-child(9) {
  background-color: #ff9a91; left: 90%; animation-delay: -3.5s;
}
.confetti:nth-child(10) {
  background-color: #f2d74e; left: 100%; animation-delay: -2.5s;
}

@keyframes confetti {
  0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
  25% { transform: rotateZ(5deg) rotateY(360deg) translate(-5vw,15vh); }
  50% { transform: rotateZ(15deg) rotateY(720deg) translate(5vw,30vh); }
  75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw,50vh); }
  100% { transform: rotateZ(15deg) rotateY(1440deg) translate(10vw,70vh); }
}