.profile-form-card {
  background-color: rgba(8, 27, 51, 0.4);
  padding: 10px 20px;
}

.profile-upload-images .ant-upload-list.ant-upload-list-picture-card {
  text-align: center;
}
.profile-upload-images .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  background-color: rgba(8, 27, 51, 0.4);
  border-color: #ffffff1a;
}
@primary-color: #0F4D90;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #FFCF00;@error-color: #F23868;