@processColor: #00ABFF;
@completedColor: #50DF5B;
@cancelledColor: #F23868;

.order-details-item-card {
  padding: 24px 12px;
  background-color: rgba(8, 27, 51, 0.6);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.order-details-label {
  display: inline-block;
  min-width: 80px;
}

.order-details-status-process {
  color: @processColor;
}
.order-details-status-completed {
  color: @completedColor;
}
.order-details-status-cancelled {
  color: @cancelledColor;
}
.order-details-server {
  color: #FFCA00;
}

.order-details-item-img {
  border-radius: 4px;
}
@primary-color: #0F4D90;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #FFCF00;@error-color: #F23868;