.service-hours-input-main {
  padding: 24px 12px;
  color: white;
  justify-content: center;
}

.service-hours-day {
  width: 100px;
  padding-top: 4px;
  font-weight: bold;
  font-size: 16px;
}

.service-hours-input-container {
  width: 100%;
  max-width: 700px;
}

.service-hours-block-row {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.service-hours-block-remove {
  width: 40px;
  cursor: pointer;
  font-size: 16px;
  color: #f44336;
  text-align: center;
}

.service-hours-block-inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 90px);
  max-width: 560px;
}

.service-hours-block-to {
  padding: 0px 24px;
  text-align: center;
  width: initial;
}
@media (max-width: 768px){
  .service-hours-block-to {
    width: 100%;
  }
}

.service-hours-block-type {
  margin: 0px 24px 0px 24px;
  width: 130px;
}

.service-hours-block-switch {
  width: 50px;
}

.service-hours-add-block {
  padding-left: 80px;
  justify-content: flex-start;
}

@media (max-width: 768px){
  .service-hours-add-block {
    justify-content: center;
    padding: 0px;
  }
}
@primary-color: #0F4D90;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #FFCF00;@error-color: #F23868;