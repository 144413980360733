.capacity-slider .ant-slider-rail, .capacity-slider .ant-slider-track, .capacity-slider .ant-slider-step {
  height: 8px;
}
.capacity-slider .ant-slider-handle {
  width: 28px;
  height: 28px;
  top: -1px;
}
.capacity-slider .ant-slider-mark {
  padding-top: 8px;
}
.capacity-slider .ant-slider-dot {
  top: -4px;
  width: 16px;
  height: 16px;
}

.capacity-slider .ant-slider-dot.ant-slider-dot-active, .capacity-slider .ant-slider-handle {
  background-color: #c8c3d6;
  border-color: #c8c3d6;
}
.capacity-slider .ant-slider-dot {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.capacity-slider:hover .ant-slider-dot.ant-slider-dot-active, .capacity-slider .ant-slider-handle {
  background-color: #a397c9;
  border-color: #a397c9;
}
.capacity-slider:hover .ant-slider-dot {
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.capacity-slider .ant-slider-mark-text {
  color: rgb(198, 198, 198);
}
.capacity-slider .ant-slider-mark-text-active {
  color: white;
}
@primary-color: #0F4D90;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #FFCF00;@error-color: #F23868;